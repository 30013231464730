import { createStore } from "redux"
import reducer from "../reducers"

const initialState = {
    lang: "ES",
    coords: {
        color: `#dcddde`,
        fontSize: `11px`,
        position: `fixed`,
        padding: `0px 5px`,
        opacity: 0
    },
    width: {},
    height: {},
    mute: true
}

export const store = createStore(reducer, initialState)

