export default (state, action) => {
    
    switch (action.type) {
        case "SET_LANGUAGE":
            return {
                ...state,
                lang: action.lang
            }
        case "ABOUT_COORDS":
            return {
                ...state,
                coords: action.coords
            }
        case "WINDOW_WIDTH":
            return {
                ...state,
                width: action.width
            }
        case "WINDOW_HEIGHT":
            return {
                ...state,
                height: action.height
            }
        case "TOGGLE_MUTE":
            return {
                ...state,
                mute: action.mute
            }
        default:
            return state
    }
}